import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AccordionService {
  private isExpanded: boolean = false;

  setExpanded(value: boolean): void {
    this.isExpanded = value;
  }

  getExpanded(): boolean {
    return this.isExpanded;
  }
}
