import { Component, OnInit } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { AccordionService } from '../../services/accordion.service';

@Component({
  selector: 'spai-navbar-container',
  templateUrl: './navbar-container.component.html',
  styleUrls: ['./navbar-container.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    MatMenuModule,
    MatGridListModule,
    RouterModule,
    MatExpansionModule,
  ],
})
export class NavbarContainerComponent implements OnInit {
  userName: string;
  isAccordionExpanded: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private accordionService: AccordionService
  ) {
    this.userName = authService.getUserName();
  }

  ngOnInit(): void {
    this.isAccordionExpanded = this.accordionService.getExpanded();
  }

  toggleAccordion(expanded: boolean): void {
    this.accordionService.setExpanded(expanded);
  }

  logOutClick(): void {
    this.authService.clearAuthData();
    this.router.navigate(['login']);
  }
}
